import React, { FC } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { EMAIL, IMAGE_CONTENT_DIRECTION } from '../../constants';
import { SectionImageContentComponent } from '../shared';
import { MainContent } from '../layout/main-content';
import { SectionTextContent } from '../shared';

export const AboutUsJoin: FC = () => {
  return (
    <>
      <section className="bg-accent-light text-primary" id="join-the-team">
        <MainContent>
          <div className="py-20">
            <SectionImageContentComponent
              cls="my-4 md:my-12"
              direction={IMAGE_CONTENT_DIRECTION.RTL}
              fgImage={
                <StaticImage
                  src="../../content/img/about/join.png"
                  alt=""
                  width={578}
                  height={515}
                  placeholder="blurred"
                />
              }
              fgImageCls="h-full flex items-center justify-center"
              content={
                <div className="col-start-1 col-span-full pb-14 md:col-start-4 md:col-span-8 flex flex-col gap-y-8 items-start mb-3">
                  <div className="flex flex-col gap-y-8 text-left text-primary text-base md:text-xl">
                    <SectionTextContent
                      titlePosition="text-center"
                      title="Careers"
                      cls={'my-0 text-center'}
                      titleCls="text-center md:text-left ff-secondary"
                    />
                    <p className="text-center md:text-left mb-0.5 pr-0 leading-relaxed text-grey-darker">
                      We partner with most talented professionals in both digital assets and traditional
                      finance to advance digital assets as a trusted asset class.
                      Email us at <a href={`mailto:${EMAIL.JOBS}`} className="font-semibold no-underline">{EMAIL.JOBS}</a> for open positions.
                    </p>
                  </div>
                </div>
              }
            />
          </div>
        </MainContent>
      </section>
    </>
  );
};
