import React from 'react';
import Fade from 'react-reveal/Fade';
import { MainContent } from '../layout/main-content';
import { siteData } from '../../data/site-data';
import { AppIcon } from 'common';

export const AboutUsCommitments = () => {
  /**
   * DOM
   */
  return (
    <div className="bg-primary py-8 md:py-12 lg:py-20">
      <MainContent>
        <div className="flex flex-col items-center">
          <AppIcon icon="stablehouse-lettermark-dark" bg="" size={64} />
          <p className="ff-secondary text-center text-white typo-h3 my-3">
            Our commitments
          </p>
        </div>
        <div className="flex flex-col md:flex-row justify-evenly gap-x-6 gap-y-6 md:gap-y-10">
          {siteData.aboutUsPage.sectionCommitments.map(item => {
            return (
              <Fade>
                <div className="flex flex-col items-center flex-1">
                  <p className="typo-20 font-semibold my-4 text-white text-center">{item.title}</p>
                  <p className="text-gray-400 text-center typo-b2">{item.description}</p>
                </div>
              </Fade>
            );
          })}
        </div>
      </MainContent>
    </div>
  );
};
