import React, { FC } from 'react';
import Fade from 'react-reveal/Fade';
import { SectionTextContent } from '../shared/section-text-content';
import { MainContent } from '../layout/main-content';
import { SectionImage } from '../shared';
import { siteData } from '../../data/site-data';

export const AboutUsInvestors: FC = () => {
  /**
   * DOM
   */
  return (
    <section className="bg-accent-light">
      <MainContent cls="grid grid-cols-12 grid-rows-1 gap-5 items-center pt-16 lg:pt-20 pb-20 lg:pb-32">
        <div className="col-start-1 col-span-12 lg:col-start-2 lg:col-span-5 mb-10 md:mb-0">
          <Fade>
            <>
              <SectionTextContent
                title={siteData.aboutUsPage.sectionInvestors.title}
                description={siteData.aboutUsPage.sectionInvestors.description}
                cls={'md:mb-0'}
                titleCls="text-center md:text-center lg:text-left ff-secondary"
                descriptionCls="text-center md:text-center lg:text-left text-grey-darker text-base"
              />
            </>
          </Fade>
        </div>
        <div className="col-start-1 col-span-12 lg:col-start-8 lg:col-span-4">
          <Fade>
            <div className="flex-1 flex flex-row items-center mb-9 flex-wrap">
              {siteData.aboutUsPage.sectionInvestors.images.map(item => {
                return (
                  <a
                    key={item.name}
                    href={item.url}
                    target="_blank"
                    className="flex w-full sm:w-2/4 justify-center items-center"
                  >
                    <SectionImage name={item.name} />
                  </a>
                );
              })}
            </div>
          </Fade>
        </div>
      </MainContent>
    </section>
  );
};
