import React, { FC } from 'react';
import cx from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';
import Fade from 'react-reveal/Fade';
import { MainContent } from '../layout/main-content';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { SectionTextContent } from '../shared';

export const AboutUsTeam: FC = () => {
  /**
   * Hooks
   */
  const breakpoints = useBreakpoint();

  /**
   * DOM
   */
  const isMobile = breakpoints.xs || breakpoints.sm ? true : false;
  const titleCls = cx(
    'text-primary font-semibold',
    {
      'typo-b2': isMobile,
    },
    {
      'text-xl': !isMobile,
    }
  );
  const subTitleCls = cx(
    'text-primary text-grey-darker',
    {
      'typo-b2': isMobile,
    },
    {
      'text-xl': !isMobile,
    }
  );
  return (
    <>
      <section className="bg-light-accent text-primary">
        <MainContent cls="py-20">
          <SectionTextContent
            titlePosition="text-center"
            title="Our team"
            cls={'mt-4 mb-12 lg:mb-12 text-center'}
            titleCls="text-center md:text-center ff-secondary"
          />
          <section className=" lg:grid lg:grid-cols-14 gap-x-5 p-0 items-center team w-full">
            <div className="col-start-1 col-span-full lg:col-start-0 lg:col-span-16 flex flex-wrap flex-row justify-around content-start gap-y-16 gap-x-2 lg:gap-x-4">
              <div className="grid gap-6 grid-cols-2 md:grid-cols-4 md:gap-5 text-primary border-b-4 border-transparent w-full">
                <Fade>
                  {/* member  */}
                  <div className="">
                    <div className="hover-border-bottom-accent">
                      <StaticImage
                        src={`https://stablehouse-assets.s3.eu-west-1.amazonaws.com/web/team/phil.png`}
                        alt={`Phil - CEO`}
                        placeholder="blurred"
                        layout="fullWidth"
                      />
                      <div className="mt-1 py-4">
                        <p className={titleCls}>Philippe Bekhazi</p>
                        <p className={subTitleCls}>CEO</p>
                      </div>
                    </div>
                  </div>
                  {/* member  */}
                  <div className="">
                    <div className="hover-border-bottom-accent">
                      <StaticImage
                        src={`https://stablehouse-assets.s3.eu-west-1.amazonaws.com/web/team/mc.png`}
                        alt={`Michael - President & GM`}
                        placeholder="blurred"
                        layout="fullWidth"
                      />
                      <div className="mt-1 py-4">
                        <p className={titleCls}>Michael Chauliac</p>
                        <p className={subTitleCls}>President & GM</p>
                      </div>
                    </div>
                  </div>
                  {/* member  */}
                  <div className="">
                    <div className="hover-border-bottom-accent">
                      <StaticImage
                        src={`https://stablehouse-assets.s3.eu-west-1.amazonaws.com/web/team/aja.png`}
                        alt={`Aja - CRCO`}
                        placeholder="blurred"
                        layout="fullWidth"
                      />
                      <div className="mt-1 py-4">
                        <p className={titleCls}>Aja Heise</p>
                        <p className={subTitleCls}>CRCO</p>
                      </div>
                    </div>
                  </div>
                  {/* member  */}
                  <div className="">
                    <div className="hover-border-bottom-accent">
                      <StaticImage
                        src={`https://stablehouse-assets.s3.eu-west-1.amazonaws.com/web/team/mark.png`}
                        alt={`Mark - CFO`}
                        placeholder="blurred"
                        layout="fullWidth"
                      />
                      <div className="mt-1 py-4">
                        <p className={titleCls}>Mark Brazier</p>
                        <p className={subTitleCls}>CFO</p>
                      </div>
                    </div>
                  </div>
                </Fade>
                <Fade>
                  {/* member  */}
                  <div className="">
                    <div className="hover-border-bottom-accent">
                      <StaticImage
                        src={`https://stablehouse-assets.s3.eu-west-1.amazonaws.com/web/team/augusto.png`}
                        alt={`Augusto - CTO`}
                        placeholder="blurred"
                        layout="fullWidth"
                      />
                      <div className="mt-1 py-4">
                        <p className={titleCls}>Augusto Proiete</p>
                        <p className={subTitleCls}>CTO</p>
                      </div>
                    </div>
                  </div>
                  {/* member  */}
                  <div className="">
                    <div className="hover-border-bottom-accent">
                      <StaticImage
                        src={`https://stablehouse-assets.s3.eu-west-1.amazonaws.com/web/team/morgan.png`}
                        alt={`Morgan - Controller`}
                        placeholder="blurred"
                        layout="fullWidth"
                      />
                      <div className="mt-1 py-4">
                        <p className={titleCls}>Morgan Norful</p>
                        <p className={subTitleCls}>Controller</p>
                      </div>
                    </div>
                  </div>
                  {/* member  */}
                  <div className="">
                    <div className="hover-border-bottom-accent">
                      <StaticImage
                        src={`https://stablehouse-assets.s3.eu-west-1.amazonaws.com/web/team/dipen.png`}
                        alt={`Dipen - Head of Engineering`}
                        placeholder="blurred"
                        layout="fullWidth"
                      />
                      <div className="mt-1 py-4">
                        <p className={titleCls}>Dipen Kamdar</p>
                        <p className={subTitleCls}>Head of Product</p>
                      </div>
                    </div>
                  </div>
                  {/* member  */}
                  <div className="">
                    <div className="hover-border-bottom-accent">
                      <StaticImage
                        src={`https://stablehouse-assets.s3.eu-west-1.amazonaws.com/web/team/antoine.png`}
                        alt={`Antoine - Product Lead`}
                        placeholder="blurred"
                        layout="fullWidth"
                      />
                      <div className="mt-1 py-4">
                        <p className={titleCls}>Antoine Corbel</p>
                        <p className={subTitleCls}>Product Lead</p>
                      </div>
                    </div>
                  </div>
                </Fade>
                <Fade>
                  {/* member  */}
                  <div className="">
                    <div className="hover-border-bottom-accent">
                      <StaticImage
                        src={`https://stablehouse-assets.s3.eu-west-1.amazonaws.com/web/team/dds.png`}
                        alt={`David - Head of Design`}
                        placeholder="blurred"
                        layout="fullWidth"
                      />
                      <div className="mt-1 py-4">
                        <p className={titleCls}>David Dos Santos</p>
                        <p className={subTitleCls}>Head of Design</p>
                      </div>
                    </div>
                  </div>

                  {/* member  */}
                  <div className="">
                    <div className="hover-border-bottom-accent">
                      <StaticImage
                        src={`https://stablehouse-assets.s3.eu-west-1.amazonaws.com/web/team/brad.png`}
                        alt={`Brad - Customer Success Lead`}
                        placeholder="blurred"
                        layout="fullWidth"
                      />
                      <div className="mt-1 py-4">
                        <p className={titleCls}>Brad Coetzer</p>
                        <p className={subTitleCls}>Customer Success Lead</p>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </section>
        </MainContent>
      </section>
    </>
  );
};