import React from 'react';
import { MainContent } from '../layout/main-content';
import { siteData } from '../../data/site-data';
import { SectionTextContent } from '../shared';
import { AppIcon } from 'common';

export const AboutUsRegulator = () => {
  /**
   * DOM
   */
  return (
    <div className="bg-primary border-t border-white border-opacity-10">
      <MainContent cls="grid grid-cols-12 grid-rows-1 gap-5 items-center pt-16 md:pt-20 pb-20 md:pb-32">
        <div className="col-start-1 col-span-12 md:col-span-6 lg:col-span-6">
          <div className="flex flex-col items-center mx-2 lg:mx-16 gap-6">
            <AppIcon icon="bma" size={102} height={73} bg="" />
            <SectionTextContent
              title={siteData.aboutUsPage.sectionRegulator.left.title}
              description={siteData.aboutUsPage.sectionRegulator.left.description}
              cls={'my-4 max-w-sm'}
              titleCls="text-center ff-secondary text-white"
              descriptionCls="text-center text-gray-400 typo-b2"
            />
          </div>
        </div>
        <div className="col-start-1 col-span-12 md:col-start-7 md:col-span-6 lg:col-start-7 lg:col-span-6 flex justify-center">
          <SectionTextContent
            title={siteData.aboutUsPage.sectionRegulator.right.title}
            description={siteData.aboutUsPage.sectionRegulator.right.description}
            cls={'md:mb-0 md:mx-2 max-w-lg'}
            descriptionCls="text-left text-gray-400 text-base md:text-xl"
          />
        </div>
      </MainContent>
    </div>
  );
};
