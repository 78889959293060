import React, { FC } from 'react';
import { PageLayout } from '../components/layout';
import {
  AboutUsHero,
  AboutUsCommitments,
  AboutUsTeam,
  AboutUsJoin,
  AboutUsInvestors,
  AboutUsRegulator,
} from '../components/about';

const AboutUs: FC = () => {
  /**
   * DOM
   */
  return (
    <PageLayout bgCls="bg-accent-light">
      <AboutUsHero />
      <AboutUsCommitments />
      <AboutUsRegulator />
      <AboutUsInvestors />
      <AboutUsTeam />
      <AboutUsJoin />
    </PageLayout>
  );
};

export default AboutUs;
